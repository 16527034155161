import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../templates/layout'

const Container = styled.div`
  margin-top: ${ props => props.theme.pagePadding };
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media ${ props => props.theme.device.sm } {
    margin-top: ${ props => props.theme.pagePaddingMobile };
  }

  section {
    margin-bottom: 5.75rem;

    @media ${ props => props.theme.device.sm } {
      margin-top: 4.375rem;
    }
  }

  p {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;

    &:first-child {
      margin-top: 1.5rem;
    }

    @media ${ props => props.theme.device.sm } {
      margin-top: 1.56rem;
      margin-bottom: 1.56rem;

      &:first-child {
        margin-top: 0.75rem;
      }
    }
  }
`

const Image = styled(GatsbyImage)`
  margin-top: 3.125rem;
  display: none;
`

const ClientsImageDarkDesktop = styled(Image)`
  display: block;

  @media ${ props => props.theme.device.sm }  {
    display: none;
  }

  .light-theme & {
    display: none;
  }
`

const ClientsImageDarkMobile = styled(Image)`
  @media ${ props => props.theme.device.sm }  {
    display: block;
  }

  .light-theme & {
    display: none;
  }
`
const ClientsImageLightDesktop = styled(Image)`
  .light-theme & {
    display: block;

    @media ${ props => props.theme.device.sm }  {
      display: none;
    }
  }
`

const ClientsImageLightMobile = styled(Image)`
  .light-theme & {
    @media ${ props => props.theme.device.sm }  {
      display: block;
    }
  }
`

const InfoPage = ({
  data: {
    datoCmsInfo: {
      profile,
      services,
      agencies,
      internships,
      clientsDesktopDark,
      clientsDesktopLight,
      clientsMobileDark,
      clientsMobileLight
    }
  },
  location,
  pageContext
}) => {
  return (
    <Layout location={location} pageContext={pageContext}>
      <Container>
        <section>
          <h1>Profile</h1>
          <div dangerouslySetInnerHTML={{ __html: profile }}/>
        </section>
        <section>
          <h1>Services</h1>
          <div dangerouslySetInnerHTML={{ __html: services }}/>
        </section>
        <section>
          <h1>Agencies</h1>
          <div dangerouslySetInnerHTML={{ __html: agencies }}/>
        </section>
        <section>
          <h1>Clients</h1>
          <ClientsImageDarkDesktop fluid={clientsDesktopDark.fluid}/>
          <ClientsImageDarkMobile fluid={clientsMobileDark.fluid}/>
          <ClientsImageLightDesktop fluid={clientsDesktopLight.fluid}/>
          <ClientsImageLightMobile fluid={clientsMobileLight.fluid}/>
        </section>
        <section>
          <h1>Internships</h1>
          <div dangerouslySetInnerHTML={{ __html: internships }}/>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query InfoPageQuery {
    datoCmsInfo {
      profile
      services
      agencies
      internships
      clientsDesktopDark {
        fluid(maxWidth: 1200, imgixParams: {
          fm: "png",
          auto: "compress"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      clientsMobileDark {
        fluid(maxWidth: 1200, imgixParams: {
          fm: "png",
          auto: "compress"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      clientsDesktopLight {
        fluid(maxWidth: 1200, imgixParams: {
          fm: "png",
          auto: "compress"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      clientsMobileLight {
        fluid(maxWidth: 1200, imgixParams: {
          fm: "png",
          auto: "compress"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`

export default InfoPage
